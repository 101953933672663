import Vue from 'vue'
import request from '@/utils/request'


//get
export function getAction(url,parameter) {
  return request({
    url: url,
    method: 'get',
    params: parameter
  })
}

//post
export function postAction(url,parameter,responseType) {  
  return request({
    url: url,
    method:'post',
    data: parameter,
    responseType: responseType
  })
}

//put
export function putAction(url,parameter) {
  return request({
    url: url,
    method:'put',
    data: parameter
  })
}

//deleteAction
export function deleteAction(url,parameter) {
  return request({
    url: url,
    method: 'delete',
    params: parameter
  })
}










