import Vue from "vue";
import VueRouter from "vue-router";
import BasePage from "@/layout/basePage"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
	meta: { title: "首页" },
    component: () => import("@/views/home"), 
  },
  {
    path: "/",
    component: BasePage,
    name: "basePage",
    meta: { title: "主页" },
    redirect: "bzsq",
    children: [
	  {
	    path: "/bzsq",
	    name: "bzsq",
		meta: { title: "报装申请" },
	    component: () => import("@/views/modules/bzsq.vue"),
	  },
	  {
	    path: "/gh",
	    name: "gh",
		meta: { title: "过户" },
	    component: () => import("@/views/modules/gh.vue"),
	  },
	  {
	    path: "/qjbg",
	    name: "qjbg",
		meta: { title: "气价变更" },
	    component: () => import("@/views/modules/qjbg.vue"),
	  },
	  {
	    path: "/ybgx",
	    name: "ybgx",
		meta: { title: "移表改线" },
	    component: () => import("@/views/modules/ybgx.vue"),
	  },
	  {
	    path: "/gzbx",
	    name: "gzbx",
		meta: { title: "故障报修" },
	    component: () => import("@/views/modules/gzbx.vue"),
	  },
	  {
	    path: "/qfcj",
	    name: "qfcj",
	  	meta: { title: "气费查缴" },
	    component: () => import("@/views/modules/qfcj.vue"),
	  },
	  {
	    path: "/qfcjResult",
	    name: "qfcjResult",
	  	meta: { title: "气费查缴" },
	    component: () => import("@/views/modules/qfcjResult.vue"),
	  },
	  {
	    path: "/fpdy",
	    name: "fpdy",
	  	meta: { title: "发票打印" },
	    component: () => import("@/views/modules/fpdy.vue"),
	  },
	  {
	    path: "/fpdyList",
	    name: "fpdyList",
	  	meta: { title: "发票打印" },
	    component: () => import("@/views/modules/fpdyList.vue"),
	  }, 
	  {
	    path: "/trqIndex",
	    name: "trqIndex",
	  	meta: { title: "供水供气自助一体机" },
	    component: () => import("@/views/modules/trqIndex.vue"),
	  },
	  {
	    path: "/iframe",
	    name: "iframe",
	  	meta: { title: "iframe" },
	    component: () => import("@/views/iframe.vue"),
	  } 
    ]
  },
 
];

const router = new VueRouter({
  //mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
