//自动关闭提示框
export class showToast{
	//创建弹窗
	static succecd = require('@/assets/images/succecd.png');
	static tishi = require('@/assets/images/tishi.png');
	
	static alert(option){
		const { message, status, time=3000 } = option
		// 创建弹出框容器元素
		const popupContainer = document.createElement('div');
		popupContainer.classList.add('alert-popup');
		//设置弹出框的内容和样式
		popupContainer.innerHTML = `
		    <img src="${status?showToast.succecd:showToast.tishi}"/>
		    <p>${message}</p>
		`;
		
		popupContainer.style.width = '300px';
		popupContainer.style.height = '300px';
		popupContainer.style.background = 'rgba(76, 76, 76, 0.9)';
		popupContainer.style.borderRadius = '12px';
		popupContainer.style.position = 'fixed';
		popupContainer.style.top = '50%';
		popupContainer.style.left = '50%';
		popupContainer.style.transform = 'translate(-50%, -50%)';
		
		// 将弹出框插入到页面中
		document.body.appendChild(popupContainer);
		
		setTimeout(()=>{
			popupContainer.remove()
		},time)
	}
}



/**
 * 表格时间格式化
 */
export function formatDate(cellValue) {
  if (cellValue == null || cellValue == "") return "";
  var date = new Date(cellValue)
  var year = date.getFullYear()
  var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes //+ ':' + seconds
}

