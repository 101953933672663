import Vue from "vue";
import { Button, Form, Field, RadioGroup, Radio, Tab, Tabs, List   } from 'vant';



const list = [
	Button,
	Form,
	Field,
	RadioGroup, 
	Radio,
	Tab,
	Tabs,
	List 
]
//循环注册组件
list.forEach(ele => { 
  Vue.use(ele)
})