import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./utils/request";

Vue.config.productionTip = false;
import 'amfe-flexible';
import "@/assets/style/index.scss";

// 全局引入
// import Vant from 'vant';
// import 'vant/lib/index.css';
// Vue.use(Vant);

// 按需引入vant
import "@/components/Vant.js";

import { showToast } from "@/utils/base"
Vue.prototype.$showToast = showToast
Vue.prototype.$axios = axios


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
