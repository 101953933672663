<template>
	<div class="page-container">
		<Header></Header>
		<div class="page-content">
			<router-view />
		</div>
	</div>
</template>

<script>
import Header from '@/layout/components/Header'	

export default {
	name: 'BasePage',
	components:{
		Header
	},
	data () {
	  return {
		
	  }
	},
	computed:{
	   
	},
	mounted() {
		// 初始化计时器
		this.resetTimer();
		// 监听用户操作事件
		window.addEventListener('mousemove', this.resetTimer);
		window.addEventListener('keydown', this.resetTimer);
		window.addEventListener('touchstart', this.resetTimer);
	},
	beforeDestroy() {
		clearTimeout(this.timer);
		window.removeEventListener('mousemove', this.resetTimer);
		window.removeEventListener('keydown', this.resetTimer);
		window.removeEventListener('touchstart', this.resetTimer);
	},
	methods: {
		resetTimer() {
		  clearTimeout(this.timer);
		  this.timer = setTimeout(() => {
			 this.$router.push({path:"/"})
		  }, 1000*60*3);
		}
	}
  }
</script>

<style lang="scss" scoped>
.page-container{
	height: 100vh;
	display: flex;
	flex-direction: column;
	.page-content{
		flex: 1;
		height: 0;
		overflow: hidden;
	}
}	
</style>