import Vue from 'vue'
import { getAction, deleteAction, putAction, postAction, httpAction } from '@/api/manage'


//字典
export const getDictKey = (params)=>getAction("/system/dict/data/list",params);

//报装申请
export const addBzsq = (params)=>postAction("/system/applys",params);


//过户
export const addGh = (params)=>postAction("/system/ownership",params);


//气价变更
export const addQjbg = (params)=>postAction("/system/change",params);


//移表改线
export const addYbgx = (params)=>postAction("/system/log",params);


//故障报修
export const addGzbx = (params)=>postAction("/system/repair",params);


//查询表卡信息和用户信息是否匹配
export const findCard = (params)=>postAction("/system/pay/find",params);

//获取充值二维码
export const getPayCode = (params)=>postAction("/system/pay/payCode",params);

//二维码回调
export const checkCode = (code)=>postAction(`/system/pay/check/${code}`);


//打印
export const payPrin = (code)=>postAction('/system/pay/prin');

//查询充值记录列表
export const rechargeList = (params)=>getAction('/pay/log/list',params);

//打印发票
export const nowPdf = (code)=>postAction(`/system/pay/nowPdf/${code}`);


//开票
export const getPrin = (params)=>postAction(`/system/pay/prin`, params);


//开票回调
export const pdFinfo = (code)=>postAction(`/system/pay/pdfinfo/${code}`);



