<template>
	 <div class="navigation">
		<div class="returm-arrow" @click="back">{{ returnText }}</div> 
		<div class="title">{{ pageTitle }}</div>
		<div class="date">{{ nowDate }}</div>
	 </div>
</template>

<script>
import { formatDate } from "@/utils/base"
	
export default{
	name:'Header',
	data(){
		return{
			nowDate: "",
			timer: null,
			returnText: '返回' //返回至首页
		}
	},
	computed:{
		pageTitle(){
			this.returnText = this.$route.path == '/trqIndex'?'返回至首页':'返回'
			return this.$route.meta.title
		}
	},
	created() {
		 this.getNowDate()
	},
	methods:{
		 back() {
			 if(this.$route.path == '/trqIndex'){
				this.$router.push('/') 
			 }else{
				history.back(); 
			 }
		 },
		 getNowDate(){
			 this.nowDate = formatDate(new Date())
			 clearInterval(this.timer)
			 this.timer = setInterval(()=>{
				this.nowDate = formatDate(new Date())
			 }, 1000)
		 } 
	},
	beforeDestroy() {
		 clearInterval(this.timer)
	}
}	
</script>

<style lang="scss" scoped>
.navigation{
	height: 146px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	position: relative;
	.returm-arrow{
	   font-size: 32px;	
	   background: url('~@/assets/images/icon_back.png') left center no-repeat;
	   background-size: 60px auto ;
	   position: absolute;
	   left: 36px;
	   top: 50%;
	   height: 80px;
	   margin-top: -40px;
	   padding-left: 70px;
	   line-height: 80px;
	   &:active{
		   background-color: #eee;
	   }
	}
	.title{
	   font-family: YouSheBiaoTiHei;	
	   font-size: 50px;
	   color: #F17101;
	}
	.date{
	   position: absolute;	
	   right: 30px;
	   font-size: 30px;
	   color: #000;
	}
}	
</style>