import axios from 'axios'
import store from '@/store'
import router from "@/router";
//import { Message } from 'element-ui'

console.log(process.env.NODE_ENV)
//创建axios实例
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'production'? 'http://bdrq.bdshuisi.com/prod-api' : '/api',   //http://192.168.1.13/prod-apis
  timeout: 60000  //请求超时时间(因为需要调试后台,所以设置得比较大)
});



//request拦截器
service.interceptors.request.use(config => {
  if(store.state.token) {
    config.headers["token"] = store.state.token;
  }
  return config
}, error => {
    Promise.reject(error)
})

//响应拦截器
service.interceptors.response.use(response => {
    const res = response.data;
    if(res.code == "200"){
      return res;
    }else if(res.code == "401"){ 
      //store.commit('updateLogin', false)
      //store.commit('updateLoginType', '')
	  //store.commit('updateToken', '')
	  //store.commit('updateUserInfo', '')
	  //router.push("/login");    
      return Promise.resolve(res);            
    }else {
      return Promise.resolve(res);
    }
  },error => {
    console.log(error)
    //Message.$message.error(error.AxiosError.message); 
    return Promise.reject("网络异常");
  }
)

export default service