import { getDictKey } from '@/api/index'

const modules = {
	namespaced: true,
    state: {
		dictData:[],
    },
    mutations: {
		setDictData(state,{key,list}){
			let idx = state.dictData.findIndex(v=>v.key==key);
			if(idx==-1){
			   state.dictData.push({
				  key,
				  list,
			   })	
			}else{
			   state.dictData[idx].list = list 
			}
		},
    },
    getters: {
		getDictByKey: (state) => (key) => {
			//console.log(state,key)
			let arr = []
            let idx = state.dictData.findIndex(v=> v.key === key)
			if(idx!=-1){
			   arr = state.dictData[idx].list
			}
		    return arr
		},
    },
    actions: {
		//获取字典
		async getDictList({commit,state}, key){ 
			try{
			   let res = await getDictKey({
			       pageNum: 1,
			   	   pageSize: 100,
			       dictType: key 
			   });	
			   if(res.code == 200){
			      let obj = {
			   	    key: key,
			   	    list: res.rows || [],
			      }
			      commit('setDictData', obj)
			   }
			}catch(err){
			   console.log(err)	
			}
		},
    },
	modules: {},
}

export default modules